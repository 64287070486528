import React from 'react';
import './App.css';
import WFSCCoachID from './hosted/WFSCCoachID-RussellDear.png';

function Hosted() {
  return (
    <div className="app">
      <img src={WFSCCoachID} alt="img" />
    </div>
  );
}

export default Hosted;
